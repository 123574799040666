<template>
  <div class="wrapper">
    <header class="move-up">
      <div>
        <h2>Panel administracyjny - <span>Zarządzanie</span></h2>
      </div>
    </header>
    <div class="cols">
      <base-card :columns="12" class="">
        <template #default>
          <div class="p">
            <h2>Zaloguj się do panelu</h2>
            <form @submit.prevent="onFormSubmit">
              <div class="form-group">
                <div class="form-control">
                  <input
                    type="text"
                    id="email"
                    v-model="email"
                    placeholder="Twój adres email"
                  />
                </div>
              </div>
              <div class="form-group">
                <div class="form-control">
                  <input
                    type="password"
                    id="password"
                    v-model="password"
                    placeholder="************"
                  />
                </div>
              </div>
              <div class="form-group">
                <div class="form-control">
                  <button type="submit" class="btn base">Zaloguj</button>
                </div>
              </div>
            </form>
          </div>
        </template>
      </base-card>
    </div>
  </div>
</template>

<script>
import AuthService from "../services/AuthService";
import { mapActions, mapGetters } from "vuex";
import { toast } from "vue3-toastify";

export default {
  data() {
    return {
      email: "",
      password: "",
      token: "",
    };
  },
  computed: {
    ...mapGetters(["getToken"]),
  },
  methods: {
    ...mapActions(["setToken"]),
    async onFormSubmit() {
      if (this.isFormValid()) {
        try {
          const res = await AuthService.login(this.email, this.password);
          this.setToken(res.data);
          toast.success("Zalogowano");
          this.$router.push("/dashboard");
        } catch (err) {
          this.setToken("");
          toast.error("Nie udało się zalogować do aplikacji");
          err.response.data.errors.forEach((error) => toast.error(error.msg));
        }
      }
    },
    isFormValid() {
      return this.isEmailValid() && this.isPasswordValid();
    },
    isEmailValid() {
      return true;
    },
    isPasswordValid() {
      return true;
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/_base.scss";

header {
  text-shadow: $multi;
  h2 {
    text-transform: uppercase;
    color: $primary;
    font-size: 1.75em;
    span {
      text-transform: capitalize;
      font-weight: normal;
      color: white;
    }
  }
}
.res {
  margin-bottom: 1rem;
  color: red;
  &.valid {
    color: green;
  }
}
.wrapper {
  width: auto;
  max-width: 960px;
  width: 90%;
  header {
    margin-top: 3rem;
    h1 {
      font-weight: normal;
      span {
        font-weight: bold;
        text-transform: uppercase;
        border-bottom: 8px solid $primary;
      }
    }
  }
  .check-group {
    flex-direction: column;
    border: none;
    margin-bottom: 2rem;
    div {
      margin-bottom: 1rem;
    }
  }
  .form-group {
    display: flex;
    width: 100%;

    .form-control {
      width: 100%;
      margin-bottom: 1rem;
      input,
      select,
      textarea {
        width: 100%;
        padding: 1rem;
        box-sizing: border-box;
        border: 1px solid rgba(0, 0, 0, 0.2);
        border-radius: 4px;
        color: $fontLight;
      }
      &.checkbox {
        display: flex;
        align-items: center;
        input {
          width: auto;
        }
      }
      textarea {
        resize: none;
        font-family: inherit;
      }
      &.mr {
        margin-right: 1rem;
      }
    }
  }
}
</style>