import axios from "axios";

export default class AuthService {
  static async login(email, password) {
    return await axios.post(`${process.env.VUE_APP_API_URL}/login`, {
      email,
      password,
    });
  }

  static async test(token) {
    const response = await axios.post(
      `${process.env.VUE_APP_API_URL}/test`,
      {},
      { headers: { Authorization: `Bearer ${token}` } }
    );
    return response;
  }
}
